import FieldCondition from "@/components/reporter-intake-form-field/field-condition"
import { checkArrayEqualityIgnoreOrder } from "@/utils"
import { FIELD_TYPES } from "@/constants"
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "FieldConditions",
  components: {
    FieldCondition,
    ThemisDecision
  },
  data: () => ({
    fieldConditionCriteria         : null,
    fieldConditionsMasterCopy      : [],
    localFieldConditions           : [],
    optionsWhichAreSelected        : new Set(),
    changeInConditionStatus        : [],
    showRemoveFieldConditionsDialog: false,
    disableAddButton               : false
  }),
  props: {
    pField                            : Object,
    pOptions                          : Array,
    pFieldConditions                  : Array,
    pFieldsToDisplay                  : Array,
    pAddingFieldConditions            : Boolean,
    pFieldConditionsRemoved           : Boolean,
    pIsRemovingFieldConditions        : Boolean,
    pSelectedFormTemplateConfiguration: Object
  },
  emits: [
    "closeDialog",
    "saveFieldConditions"
  ],
  computed: {
    fieldName() {
      return this.pField.systemName
    },
    isBooleanField() {
      return this.pField.type === FIELD_TYPES.BOOLEAN.value
    },
    isOptionListItemsCascaded() {
      return !this.isBooleanField  && !!this.pOptions.find(optionListItem => optionListItem.parentId)
    },
    childParentMap() {
      const childParentMap = {}
      this.pOptions.forEach(optionListItem => {
        childParentMap[optionListItem.id] = optionListItem.parentId
      })
      return childParentMap
    },
    nameOptionListIdMap() {
      const nameOptionListIdMap = {}
      this.pOptions.forEach(optionListItem => {
        nameOptionListIdMap[optionListItem.name] = optionListItem.id
      })
      return nameOptionListIdMap
    },
    isFieldConditionsChanged() {
      return this.changeInConditionStatus.some(status => status)
    },
    isOptionsFilled() {
      return this.localFieldConditions.every(fieldCondition => fieldCondition.fieldConditionCriteria.values.length)
    },
    isFieldsFilled() {
      return this.localFieldConditions
        .every(fieldCondition => fieldCondition.fieldConditionCriteria.childrenIds.length)
    },
    isFieldConditionAddedOrRemoved(){
      return this.pFieldConditions?.length !== this.localFieldConditions.length
    },
    disableSaveButton() {
      return !(this.isFieldConditionsChanged || this.isFieldConditionAddedOrRemoved)
        || !this.isOptionsFilled || !this.isFieldsFilled
    },
    hasNoFieldConditions() {
      return this.pSelectedFormTemplateConfiguration?.fieldConditions?.length === 0
    },
    showDeleteIcon() {
      if (this.localFieldConditions.length > 1) {
        return true
      } else {
        return (!!(this.localFieldConditions[0].fieldConditionCriteria.values.length
          && this.localFieldConditions[0].fieldConditionCriteria.childrenIds.length))
      }
    }
  },
  methods: {
    handleCloseConditionsDialog() {
      this.$emit("closeDialog")
    },
    handleAddFieldCondition() {
      const emptyFieldCondition = {
        fieldConditionCriteria: {
          values          : [],
          childrenIds     : [],
          optionsToDisplay: this.getOptionsToDisplay([])
        }
      }
      this.fieldConditionsMasterCopy.push(emptyFieldCondition)
      this.localFieldConditions.push(JSON.parse(JSON.stringify(emptyFieldCondition)))
      this.changeInConditionStatus.push(false)
    },
    setFieldConditionChangeStatus(fieldCondition, index) {
      const isFieldConditionChanged  = !checkArrayEqualityIgnoreOrder(
        fieldCondition.fieldConditionCriteria.values,
        this.fieldConditionsMasterCopy[index].fieldConditionCriteria.values
      ) || !checkArrayEqualityIgnoreOrder(
        fieldCondition.fieldConditionCriteria.childrenIds,
        this.fieldConditionsMasterCopy[index].fieldConditionCriteria.childrenIds
      )
      const changeInConditionStatus  = JSON.parse(JSON.stringify(this.changeInConditionStatus))
      changeInConditionStatus[index] = isFieldConditionChanged
      this.changeInConditionStatus   = changeInConditionStatus
    },
    handleSaveFieldCondition(fieldCondition, index) {
      this.setFieldConditionChangeStatus(fieldCondition, index)
      const localFieldCondition    = this.localFieldConditions[index]
      this.optionsWhichAreSelected = new Set(fieldCondition.fieldConditionCriteria.values)

      for (let iterator = 0; iterator < this.localFieldConditions.length; iterator++) {
        if (iterator !== index) {
          if (this.localFieldConditions[iterator].fieldConditionCriteria.values.length) {
            for (const value of this.localFieldConditions[iterator].fieldConditionCriteria.values) {
              this.optionsWhichAreSelected.add(value)
            }
          }
        }
      }

      const values                                           = fieldCondition.fieldConditionCriteria.values
      const childrenIds                                      = fieldCondition.fieldConditionCriteria.childrenIds
      const uniqueValues                                     = Array.from(new Set(values))
      const uniqueChildrenIds                                = Array.from(new Set(childrenIds))
      localFieldCondition.fieldConditionCriteria.values      = uniqueValues
      localFieldCondition.fieldConditionCriteria.childrenIds = uniqueChildrenIds
      for (const fieldCondition of this.localFieldConditions) {
        fieldCondition.fieldConditionCriteria.optionsToDisplay =
            this.getOptionsToDisplay(fieldCondition.fieldConditionCriteria.values)
      }
      this.localFieldConditions = JSON.parse(JSON.stringify(this.localFieldConditions))
    },
    optionListIdsToUseForCascadedOptions(valueIdsToDelete, childParentMap) {
      const parentToDelete = new Set()
      for (const value of valueIdsToDelete) {
        if (value in childParentMap) {
          parentToDelete.add(childParentMap[value])

          if (Object.values(childParentMap).includes(value)) {
            continue
          } else {
            delete childParentMap[value]
          }
        }
      }

      if (parentToDelete.size) {
        valueIdsToDelete = Array.from(parentToDelete)
        this.optionListIdsToUseForCascadedOptions(valueIdsToDelete, childParentMap)
      }
      return Object.keys(childParentMap)
    },
    buildNestedTree(optionListItems) {
      const nestedOptionListItems = []
      const optionListItemMap     = {}
      optionListItems.forEach(optionListItem => {
        optionListItemMap[optionListItem.id] = { ...optionListItem, children: [] }
      })

      optionListItems.forEach(optionListItem => {
        const parent = optionListItemMap[optionListItem.parentId]
        if (parent) {
          parent.children.push(optionListItemMap[optionListItem.id])
        } else {
          nestedOptionListItems.push(optionListItemMap[optionListItem.id])
        }
      })
      return nestedOptionListItems
    },
    handleSaveFieldConditions() {
      this.$emit("saveFieldConditions", this.localFieldConditions.map(fieldCondition => {
        const id                     = fieldCondition.id
        const values                 = fieldCondition.fieldConditionCriteria.values
        const childrenIds            = fieldCondition.fieldConditionCriteria.childrenIds
        const fieldConditionCriteria = []
        for (const value of values) {
          for (const childId of childrenIds) {
            fieldConditionCriteria.push({
              value,
              formTemplateConfigurationId: childId
            })
          }
        }
        const result = {
          fieldConditionCriteria
        }
        if (id) {
          result.id = id
        }
        return result
      }))
    },
    getOptionsToDisplay(values) {
      const valuesWhichNeedsToBeFilteredFromOptions = Array.from(this.optionsWhichAreSelected)
        .filter(option => !values.includes(option))
      if (this.isOptionListItemsCascaded) {
        const childParentMap        = JSON.parse(JSON.stringify(this.childParentMap))
        const optionListIdsToDelete =
          valuesWhichNeedsToBeFilteredFromOptions.map(value => this.nameOptionListIdMap[value])
        const optionListIdsToUse    = this.optionListIdsToUseForCascadedOptions(optionListIdsToDelete, childParentMap)
        const optionListItems       = this.pOptions.filter(optionListItem =>
          optionListIdsToUse.includes(optionListItem.id.toString())
        )
        return this.buildNestedTree(optionListItems)
      } else {
        if (this.isBooleanField) {
          return this.pOptions.filter(optionListItem => {
            const booleanValue = optionListItem.name === "Yes" ? "true" : optionListItem.name === "No" ? "false" : optionListItem.name
            return !valuesWhichNeedsToBeFilteredFromOptions.includes(booleanValue)
          })
        }
        return this.pOptions
          .filter(optionListItem => !valuesWhichNeedsToBeFilteredFromOptions.includes(optionListItem.name))
      }
    },
    handleRemoveAllFieldConditions() {
      this.$emit("removeAllFieldConditions")
    },
    handleRemoveCondition(fieldConditionIndex){
      const changeInConditionStatus = JSON.parse(JSON.stringify(this.changeInConditionStatus))
      changeInConditionStatus.splice(fieldConditionIndex, 1)
      const fieldConditions  = JSON.parse(JSON.stringify(this.localFieldConditions))
      const selectedOptions  = new Set(Array.from(this.optionsWhichAreSelected))
      const [fieldCondition] = fieldConditions.splice(fieldConditionIndex, 1)
      for (const selectedValues of fieldCondition.fieldConditionCriteria.values) {
        selectedOptions.delete(selectedValues)
      }
      for (const fieldCondition of fieldConditions) {
        fieldCondition.fieldConditionCriteria.optionsToDisplay =
          this.getOptionsToDisplay(fieldCondition.fieldConditionCriteria.values)
      }
      const isFieldOrOptionsNotFilled = fieldConditions.every(
        fieldCondition => !fieldCondition.fieldConditionCriteria.values.length
        || !fieldCondition.fieldConditionCriteria.childrenIds.length
      )
      if (fieldConditions.length === 0) {
        if (this.pFieldConditions?.length) {
          this.showRemoveFieldConditionsDialog = true
          return
        } else {
          fieldCondition.fieldConditionCriteria.values      = []
          fieldCondition.fieldConditionCriteria.childrenIds = []
          fieldConditions.push(fieldCondition)
        }
      } else if (isFieldOrOptionsNotFilled) {
        if (this.pFieldConditions?.length) {
          this.showRemoveFieldConditionsDialog = true
          return
        }
      }
      this.changeInConditionStatus = [...changeInConditionStatus]
      this.localFieldConditions    = fieldConditions
      this.optionsWhichAreSelected = selectedOptions
    }
  },
  watch: {
    pFieldConditions: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.optionsWhichAreSelected = new Set()
          for (const fieldCondition of newValue) {
            for (const fieldConditionCriteria of fieldCondition.fieldConditionCriteria) {
              this.optionsWhichAreSelected.add(fieldConditionCriteria.value)
            }
          }

          this.fieldConditionsMasterCopy = newValue.map(fieldCondition => {
            const values            = fieldCondition.fieldConditionCriteria.map(fieldConditionCriteria =>
              fieldConditionCriteria.value)
            const childrenIds       = fieldCondition.fieldConditionCriteria.map(fieldConditionCriteria =>
              fieldConditionCriteria.formTemplateConfigurationId)
            const uniqueValues      = Array.from(new Set(values))
            const uniqueChildrenIds = Array.from(new Set(childrenIds))
            const optionsToDisplay  = this.getOptionsToDisplay(uniqueValues)
            return {
              id                    : fieldCondition.id,
              fieldConditionCriteria: {
                values     : uniqueValues,
                childrenIds: uniqueChildrenIds,
                optionsToDisplay
              }
            }
          })
        }

        if (!this.fieldConditionsMasterCopy.length) {
          this.fieldConditionsMasterCopy.push({
            fieldConditionCriteria: {
              values          : [],
              childrenIds     : [],
              optionsToDisplay: this.getOptionsToDisplay([])
            }
          })
        }
        this.localFieldConditions    = JSON.parse(JSON.stringify(this.fieldConditionsMasterCopy))
        this.changeInConditionStatus = new Array(this.localFieldConditions.length).fill(false)
      }
    },
    pFieldConditionsRemoved: {
      handler: function(value) {
        if (value) {
          this.showRemoveFieldConditionsDialog = false
        }
      }
    },
    pIsRemovingFieldConditions: {
      immediate: true,
      handler  : function(value) {
        this.$DECISIONS.REMOVE_ALL_FIELD_CONDITIONS.pActions[0].buttonProps.disabled = value
        this.$DECISIONS.REMOVE_ALL_FIELD_CONDITIONS.pActions[1].buttonProps.loading  = value
      }
    },
    optionsWhichAreSelected: {
      immediate: true,
      handler  : function() {
        this.disableAddButton = this.getOptionsToDisplay([]).length === 0
      }
    }
  }
}