import { mapActions, mapGetters } from "vuex"
import ThemisDecision from "@/components/shared/decision"
import FieldConditions from "@/components/reporter-intake-form-field/field-conditions"
import { FIELD_TYPES, BOOLEAN_OPTIONS } from "@/constants"

export default {
  name      : "ReporterIntakeFormField",
  components: {
    ThemisDecision,
    FieldConditions
  },
  data() {
    return {
      showRemoveFieldFormDialog                           : false,
      showFieldConditionsDialog                           : false,
      fieldToBeRemoved                                    : undefined,
      parentAndChildrenOfOtherFormTemplateConfigurationIds: [],
      isEditingFieldConditions                            : false,
      parentConfigurationId                               : null
    }
  },
  computed: {
    ...mapGetters({
      formTemplates                      : "formTemplates/formTemplates",
      fieldsV2                           : "fields/fieldsV2",
      optionLists                        : "optionLists/optionLists",
      optionListItems                    : "optionListItems/optionListItems",
      formTemplateConfigurations         : "formTemplateConfigurations/formTemplateConfigurations",
      isUpdatingMandatory                : "formTemplateConfigurations/isUpdatingMandatory",
      isMandatoryUpdated                 : "formTemplateConfigurations/isMandatoryUpdated",
      formTemplateConfigurationRemoved   : "formTemplateConfigurations/formTemplateConfigurationRemoved",
      isRemovingFormTemplateConfiguration: "formTemplateConfigurations/isRemovingFormTemplateConfiguration",
      fieldConditionsRemoved             : "formTemplateConfigurations/fieldConditionsRemoved",
      isRemovingFieldConditions          : "formTemplateConfigurations/isRemovingFieldConditions",
      channels                           : "channels/channels",
      isAddingFieldConditions            : "formTemplateConfigurations/isAddingFieldConditions",
      fieldConditionsAdded               : "formTemplateConfigurations/fieldConditionsAdded"
    }),
    formTemplateId() {
      return +this.$route.params.id
    },
    formTemplate() {
      return this.formTemplates?.find(formTemplate => formTemplate.id === this.formTemplateId)
    },
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    selectedFormTemplateConfiguration() {
      if (this.$route.params.configurationId) {
        return this.formTemplateConfigurations.find(
          formTemplateConfiguration => formTemplateConfiguration.id === +this.$route.params.configurationId
        )
      }
    },
    isChildConfiguration() {
      return this.parentAndChildrenOfOtherFormTemplateConfigurationIds.
        includes(this.selectedFormTemplateConfiguration.id)
    },
    isParentConfiguration() {
      return this.selectedFieldConditions?.length > 0
    },
    childParentConfiguration() {
      return this.formTemplateConfigurations.find(
        formTemplateConfiguration => formTemplateConfiguration.id === this.parentConfigurationId
      )
    },
    childParentConfigurationField() {
      return this.fieldsMap[this.childParentConfiguration?.fieldId]
    },
    selectedField() {
      return this.fieldsMap[this.selectedFormTemplateConfiguration?.fieldId]
    },
    selectedFieldType() {
      return Object.values(FIELD_TYPES).find(fieldType => fieldType.value === this.selectedField.type)
    },
    optionListNameOfSelectedField() {
      return this.optionListsMap[this.selectedField.optionListId]?.name
    },
    fieldTypeNameOfSelectedField() {
      return this.selectedFieldType?.name
    },
    fieldTypeIconOfSelectedField() {
      return this.selectedFieldType?.icon
    },
    showOptionListDetails() {
      return FIELD_TYPES.OPTION_LIST.value === this.selectedField.type
    },
    channelsMap() {
      const channelsMap = new Object()
      for (const channel of this.channels) {
        channelsMap[channel.id] = channel
      }
      return channelsMap
    },
    allowedFieldTypes() {
      return this.selectedField.type === FIELD_TYPES.OPTION_LIST.value ||
        this.selectedField.type === FIELD_TYPES.BOOLEAN.value
    },
    showManageConditionsButton() {
      return this.allowedFieldTypes &&
        !this.isChildConfiguration
    },
    selectedFieldName() {
      return this.selectedField.systemName
    },
    filteredFormTemplateConfigurations() {
      return this.formTemplateConfigurations
        .filter(config => config.formTemplateId === this.formTemplate.id)
    },
    optionsForConditions() {
      if (this.selectedField.type === FIELD_TYPES.BOOLEAN.value) {
        return BOOLEAN_OPTIONS
      } else {
        const optionListItems = this.optionListItems.filter(optionListItem =>
          optionListItem.optionListId === this.selectedField?.optionListId)
        return optionListItems
      }
    },
    alertForChildShortText() {
      return this.selectedField.type === FIELD_TYPES.SHORT_TEXT.value &&
        this.isChildForSelectedFormTemplateConfiguration
    },
    displayAlertInformation() {
      if (this.isParentConfiguration) {
        return this.$t("1846")
      }
      if (this.isChildConfiguration) {
        return this.$t("1847", { fieldName: this.childParentConfigurationField.systemName })
      }
      return null
    },
    selectedFieldConditions() {
      return this.selectedFormTemplateConfiguration.fieldConditions
    },
    fieldsToDisplay() {
      return this.filteredFormTemplateConfigurations
        .filter(config => config.fieldId !== this.selectedField.id &&
          !this.parentAndChildrenOfOtherFormTemplateConfigurationIds.includes(config.id)
        )
        .map(config => {
          const fieldId = config.fieldId
          return {
            id            : config.id,
            formTemplateId: config.formTemplateId,
            systemName    : this.fieldsMap[fieldId]?.systemName
          }
        })
    },
    isBooleanField() {
      return this.selectedField.type === FIELD_TYPES.BOOLEAN.value
    }
  },
  methods: {
    ...mapActions({
      notify                                    : "shared/notify",
      updateFormTemplateConfiguration           : "formTemplateConfigurations/updateFormTemplateConfiguration",
      removeFormTemplateConfiguration           : "formTemplateConfigurations/removeFormTemplateConfiguration",
      addFormTemplateConfigurationFieldCondition: "formTemplateConfigurations/addFormTemplateConfigurationFieldCondition",
      removeAllConditionsFromFields             : "formTemplateConfigurations/removeAllConditionsFromFields"
    }),
    handleRemoveFormTemplateConfiguration() {
      this.fieldToBeRemoved = this.selectedField
      this.removeFormTemplateConfiguration(this.selectedFormTemplateConfiguration.id)
    },
    getChannelName(channelId) {
      return this.channelsMap[channelId]?.name
    },
    handleSaveFieldConditions(fieldConditions) {
      this.isEditingFieldConditions = this.selectedFormTemplateConfiguration.fieldConditions?.length > 0
      this.addFormTemplateConfigurationFieldCondition({
        id            : this.selectedFormTemplateConfiguration.id,
        formTemplateId: this.formTemplateId,
        data          : fieldConditions
      })
    },
    handleRemoveAllConditions() {
      this.removeAllConditionsFromFields({
        id            : this.selectedFormTemplateConfiguration.id,
        formTemplateId: this.formTemplateId
      })
    }
  },
  watch: {
    formTemplateConfigurationRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "915",
            parameters: {
              fieldSystemName : this.fieldToBeRemoved.systemName,
              formTemplateName: this.formTemplate.name
            }
          })
          this.showRemoveFieldFormDialog = false
          this.fieldToBeRemoved          = undefined

          this.$router.push({
            name  : "reporter-intake-form",
            params: {
              id: this.formTemplateId
            }
          })
        }
      }
    },
    isRemovingFormTemplateConfiguration: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_FORM_FIELD.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_FORM_FIELD.pActions[1].buttonProps.loading  = newValue
      }
    },
    fieldConditionsAdded: {
      handler: function(value) {
        if (value) {
          if (this.isEditingFieldConditions) {
            this.notify({
              type: "success",
              text: "1851"
            })
            this.isEditingFieldConditions = false
          } else {
            this.notify({
              type: "success",
              text: "1807"
            })
          }
          this.showFieldConditionsDialog = false
        }
      }
    },
    fieldConditionsRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "1842"
          })
          this.showFieldConditionsDialog = false
        }
      }
    },
    filteredFormTemplateConfigurations: {
      immediate: true,
      handler  : function(configs) {
        const parentAndChildrenOfOtherFormTemplateConfigurationIds = new Set()
        for (const config of configs) {
          if (config.id !== this.selectedFormTemplateConfiguration.id) {
            if (config.fieldConditions?.length) {
              parentAndChildrenOfOtherFormTemplateConfigurationIds.add(config.id)
              for (const fieldCondition of config.fieldConditions) {
                for (const criteria of fieldCondition.fieldConditionCriteria) {
                  if (criteria.formTemplateConfigurationId === this.selectedFormTemplateConfiguration.id) {
                    this.parentConfigurationId = config.id
                  }
                  parentAndChildrenOfOtherFormTemplateConfigurationIds.add(criteria.formTemplateConfigurationId)
                }
              }
            }
          }
        }
        this.parentAndChildrenOfOtherFormTemplateConfigurationIds =
          Array.from(parentAndChildrenOfOtherFormTemplateConfigurationIds)
      }
    }
  }
}